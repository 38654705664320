export default {
  getUserInfo: '/api/userInfo/login/exchange', // 获取用户信息

  getRuleList: '/api/role/listAll', //获取角色列表
  addRule: '/api/role/add', // 新增角色
  editRule: '/api/role/edit', // 编辑角色
  getRuleDetails: '/api/role/roleDetails', // 获取角色详情
  deleteRule: '/api/role/del', // 删除角色
  switchRule: '/api/role/switch', // 角色开始或关闭
  ruleCascadeList: '/api/menu/menuCascadeList', // 获取角色全量数据

  getBrandAndCarModel: '/api/brand/getBrandAndCarModel', // 获取品牌车型全量数据
  getBrandList: '/api/brand/listAll', // 获取品牌列表
  getBrandDetails: '/api/brand/brandDetails', // 获取品牌详情
  deleteBrand: '/api/brand/delBrand', // 删除品牌
  addBrand: '/api/brand/add', // 新增品牌
  switchBrand: '/api/brand/switch', // 品牌开启或关闭
  brandSearch: '/api/brand/getAllBrand', // 品牌查询(车型管理搜索页面使用)
  editBrand: '/api/brand/edit', // 编辑品牌
  setExamineProcess: '/api/brand/setup', // 品牌和车型设置审核流程
  getExamineProcess: '/api/brand/verifyDetails', // 获取品牌和车型审核流程详情
  getCarModelList: '/api/carModel/listAll', // 获取车型列表
  switchCarModel: '/api/carModel/switch', // 车型开启或关闭
  getCarModelDetails: '/api/carModel/carModelDetails', // 车型详情
  editCarModel: '/api/carModel/edit', // 编辑车型
  addCarModel: '/api/carModel/add', // 新增车型
  deleteCarModel: '/api/carModel/delCarModel', // 删除车型

  getUserList: '/api/userInfo/listAll', // 获取人员列表
  userHandover: '/api/userInfo/userHandover', // 用户交接
  deleteUser: '/api/userInfo/del', // 删除用户
  getUserDetails: '/api/userInfo/userDetails', // 获取用户详情
  addUser: '/api/userInfo/add', // 新增用户
  editUser: '/api/userInfo/edit', // 编辑用户
  addUserRoleList: '/api/role/addUserRoleList', // 新增用户时获取角色列表
  switchUser: '/api/userInfo/switch', // 用户开启或关闭
  resetPassword: 'api/userInfo/resetPassword', // 重置密码
  unlock: 'api/userInfo/unlock', // 解锁

  getTaglibList: '/api/tag/listAll',//获取标签库列表
  addTaglib: '/api/tag/addTagLibrary',//添加标签库
  deleteTaglib: '/api/tag/delTag',//删除标签库  删除标签
  editTaglib: '/api/tag/tagLibEdit',//编辑标签库
  getTagList: '/api/tag/subTabListAll',//获取标签列表
  getTagDetail: '/api/tag/tagLibDetails',//获取标签详情
  checkSwitch: '/api/tag/switch',//标签开启或者关闭接口
  tagCascadeData: '/api/tag/tagCascadeData',//添加标签级联列表
  tagCascadeDataTo: '/api/tag/tagCascadeDataTo',//编辑时标签级联列表
  subTabAdd: '/api/tag/subTabAdd',//标签新增
  subTabEdit: '/api/tag/subTabEdit',//标签编辑

  obsFileUpload: '/api/obs/fileUpload', // 单个文件上传

  searchLikeUser: '/api/userInfo/searchLikeUser', // 根据名称和工号查询用户

  getChannelList: '/api/channel/listAll', //  获取渠道列表
  editChannel: '/api/channel/edit', // 编辑渠道
  switchChannel: '/api/channel/switch', // 渠道开启 关闭
  configChannel: '/api/channel/configChannel', // 配置渠道
  channelDetails: '/api/channel/channelDetails', // 渠道详情
  getTagListChannel: '/api/channel/getTagList', // 渠道获取标签列表

  getDictList: '/api/dictionary/getDictList', // 获取字典名称列表
  getOneDictItemListById: '/api/dictionary/getOneDictItemListById', // 根据字典名称ID获取字典的一级列表
  saveLanguageDictItem: '/api/dictionary/saveLanguageDictItem', // 字典项多语言维护
  getTwoDictItemListById: '/api/dictionary/getTwoDictItemListById', // 根据字典一级id获取二级数据
  addOrUpdate: '/api/dictionary/addOrUpdate', // 字典添加或编辑
  delDictItem: '/api/dictionary/delDictItem', // 字典删除
  switchDict: '/api/dictionary/switch', // 字典开启关闭
  getDictBrandList: '/api/dictionary/getBrandList', // 字典品牌列表

  getTreeDictItem: '/api/dictionary/getTreeDictItem', // 字典列表

  getDepartmentList: '/api/department/listAll', // 部门列表  
  addDepartment: '/api/department/add', // 添加部门
  editDepartment: '/api/department/edit', // 编辑部门
  deleteDepartment: '/api/department/delete', // 删除部门
  switchDepartment: '/api/department/switch', // 启用禁用部门
  getDepartmentDetails: '/api/department/detail', // 部门详情
  saveLoginBackground: '/api/loginBackground/saveLoginBackground', // 保存背景图设置
  getAllLoginBackground: '/api/loginBackground/getAllLoginBackground', // 获取背景图设置
};